import base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import apiRequest from "../axios";
import { AxiosResponse } from "axios";
import { ISSignedUrlResponse } from "../globalAPI/Auth/types";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import DefaultImage from "assets/images/default.png";
import { UserSettingsContext } from "store/UserSettingsContext";
import { localeLabelsTypes } from "locales/@types";

export const encodeString = (otpId: string) => {
  try {
    console.log(otpId);
    const id = Utf8.parse(otpId);
    const base64Id = base64.stringify(id);
    return base64Id.toString();
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const OtpDecodeBase64Id = (base64Id: string) => {
  try {
    const bytes = base64.parse(base64Id);
    const originalText = Utf8.stringify(bytes);
    return originalText;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const ApiGetSignedUrls = async (
  fileKey: string
): Promise<AxiosResponse<ISSignedUrlResponse, any>> => {
  try {
    const res = await apiRequest({
      method: "GET",
      url: `/get_pre_signed_url?fileKey=${fileKey}`,
    });
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

function fileToBinary(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const binaryData = reader.result;
      resolve(binaryData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export const uploadFile = async (file: File, fileKey: string) => {
  try {
    const res = await ApiGetSignedUrls(fileKey);
    console.log("res file", res);

    const response = await fetch(res?.data?.signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
      redirect: "follow",
    });
    console.log("response file", response);
    return Promise.resolve({
      fileKey,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const handleImagesPath = (path: string | null | undefined) => {
  if (
    path &&
    path !== "null" &&
    path !== "undefined" &&
    path != "static/media/logo.ab2029af7695b0c0cffa.png"
  ) {
    return getImageURL(path);
  } else {
    return DefaultImage;
  }
};

export const getTimeDuration = (createdAt: string) => {
  const currentTime = dayjs();
  const createdAtTime = dayjs(createdAt);

  const hoursAgo = currentTime.diff(createdAtTime, "hour");
  return hoursAgo;
};

export const getTimeDifference = (start: string | Date, end: string | Date) => {
  const endTime = dayjs(end);
  const startTime = dayjs(start);

  const hoursAgo = endTime.diff(startTime, "hour");

  return hoursAgo;
};
export const getImageURL = (path: string | null | undefined) => {
  return `https://bear-document.s3.eu-central-1.amazonaws.com/${path}`;
};

export const useCustomScrollBehavior = (
  elementId = "root",
  addScroll = false,
  scroll = "scroll" as "hidden" | "scroll"
) => {
  useEffect(() => {
    const element = document.getElementById(elementId);

    if (!element) {
      console.error(`Element with ID ${elementId} not found.`);
      return;
    }

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        if (addScroll) element.style.overflowY = "scroll";
      } else {
        element.style.overflowY = "hidden";
      }
    };

    // Initial setup
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      // and also set the overflow back to default
      if (addScroll) element.style.overflowY = "auto";
    };
  }, [elementId]);

  return null;
};

export const getLables = (
  data: string,
  currentLocale: "en" | "du",
  type: "skills" | "categories" | "branches"
) => {
  const { skills, categories, branches } = UserSettingsContext();
  switch (type) {
    case "skills":
      return skills?.find((skill) => skill._id === data)?.label?.[
        currentLocale
      ];
    case "categories":
      return categories?.find((category) => category._id === data)?.label?.[
        currentLocale
      ];
    case "branches":
      return branches?.find((branch) => branch._id === data)?.label?.[
        currentLocale
      ];
    default:
      return "";
  }
};
export const getTaskStatus = (
  data: string | undefined,
  // currentLocale: "en" | "du",
  localeLabels: localeLabelsTypes
) => {
  // status: "assigned" | "completed" | "inProgress" | "rejected";
  if (data === "assigned") {
    return localeLabels.LABEL_ASSIGNED.toUpperCase();
  } else if (data === "inProgress") {
    return localeLabels.LABEL_INPROGRESS.toUpperCase();
  } else if (data === "rejected") {
    return localeLabels.LABEL_REJECTED.toUpperCase();
  } else if (data === "approved") {
    return localeLabels.LABEL_APPROVED.toUpperCase();
  } else if (data === "declined") {
    return localeLabels.LABEL_DECLINED.toUpperCase();
  } else if (data === "paused") {
    return localeLabels.LABEL_PAUSED.toUpperCase();
  } else {
    return localeLabels.LABEL_COMPLETED.toUpperCase();
  }
};
